
.menubtn {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 2rem;
    border: 1px outset rgb(30, 30, 68);
   background-color: rgb(23, 31, 90);
   height:7rem;
   width:7rem;
   cursor:pointer;
  
}

.menubtn:hover {
   background-color: rgb(48, 48, 82);
   color:white;
}