.timeslot {
    display: flex;
    justify-content: space-between;
    
    border-style: solid;
    border-width: 0.2px 0 0.6px 0; 
    background-color: rgba(245, 245, 130, 0.684);
    color: black;
    
    
  }

  .timeslotHalf {
    border-width: 0.6px 0 0px 0;
  }


  .timeslot-first-child {
    min-width: 3rem;
    width: 5rem;
   background-color:azure ;
   border: 0;
   border-left: 0.1rem;
   border-color: blue;
   border-style:ridge;
   
   
  }

  .timeslot-child {
    width: 50rem;
    margin-right: 5px;
    font-size: 1.1rem;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    font-weight: 600;
    height: 1.5rem;
    
   

    
  }