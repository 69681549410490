.subnav 
{
    display: flex;
    justify-content: space-between;
    height: 2.5rem;

}

.rightbutton {
    float:right;
}



.selectBox {
    font-weight: bold;
    font-size: 1.2rem;
    color: rgb(4, 40, 89);
    margin-top: 0.2rem;
    width: max-content;
}