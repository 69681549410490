.button {
    font: inherit;
    border: 1px solid rgb(1, 43, 255);
    background: #2200ff;
    color: white;
    padding: 0.20rem 0.5rem;
    cursor: pointer;
    font-size: 1.15rem;
    border-radius: 12px;
    margin-top: 0.20rem;
  }
  
  .button:hover,
  .button:active {
    background: #61a5f4;
    border-color: #61a5f4;
  }
  
  .button:focus {
    outline: none;
  }
  
  button:disabled,
  button:focus:disabled,
  button:hover:disabled,
  button:active:disabled {
    background: #ccc;
    border-color: #ccc;
    color: #666666;
    cursor: not-allowed;
  }

.buttonRect {
    display: block;
    border: solid white;
    border-width: 0 10px;
    border-radius: 0;
    background: green;
    color: white;
    font-size: 18px;
    margin: -60px auto 15px;
    padding: 15px;
  }