.selectBox {
    margin-top: 20px;
}

.btn {
    margin-top: 30px; 
    margin-left: 10px;
}

.btndel {
    color: red;
    margin-top: 30px; 
    margin-left: 10px;
}