.timePart {
    background-color:moccasin;
    font-size: 1rem;
    display: inline-block; /* Set the display property to inline-block */
    width: 39%; /* Set the width of each div to 49% */
    vertical-align: top; /* Align the divs to the top */ 
    height: 2rem;
}

.linePart {
    list-style-type: none;
    background-color:dodgerblue;
    width: 100%; /* Set the container width to 100% */
    border-width: 2px;
    border-radius: 1px;
    border-color: gray;
    height: 2rem;
    margin: 0;
}

.namePart {
    display: inline-block; /* Set the display property to inline-block */
    width: 59%; /* Set the width of each div to 49% */
    vertical-align: top; /* Align the divs to the top */ 
}