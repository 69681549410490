.tenantHeader {
    background-color: yellow;
    
    margin-top: 10px;
    height: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;

}

.tenantUL {
    padding: 0;
    margin: 0;
}